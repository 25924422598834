export interface StudioState {
  appContext: any;
  authGuard: boolean;
  sectionsReady: any;
  loadingIndicators: string[];
  // @todo assign a specific type for partner state
  partner: any;
  language: any;
  purchase: any;
  plan: { [key: string]: any };
  applicantDetails: { [key: string]: string };
  deviceDetails: { [key: string]: string };
  screenerDetails: { [key: string]: string };
  quoteDetails: { [key: string]: string };
  tenant: { [key: string]: string };
  saveQuote: { [key: string]: string };
}
